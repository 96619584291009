import React from 'react'
import Header from '../components/Header'
import Login from '../components/Login'

function LoginPage() {
  return (
    <>
    <Header/>
    <Login/>
    </>
  )
}

export default LoginPage